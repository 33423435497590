import React from "react"
import { Redirect } from "react-router"
import Status from "../Base/Status"
import LoadingView from "../components/LoadingView"
import { buildProps, buildUrl, Props } from "./helpers"

class Signup extends React.Component<Props> {
  public static async getInitialProps(props) {
    return buildProps(props)
  }

  public buildUrl() {
    return buildUrl("registration", this.props)
  }

  public componentDidMount() {
    window.location.replace(this.buildUrl())
  }

  public render() {
    const href = this.buildUrl()
    if (typeof window !== "undefined") {
      // will use didmount to redirect
      return (
        <>
          <LoadingView variant="view" />
          <a rel="nofollow" href={href}>
            &nbsp;
          </a>
        </>
      )
    } else {
      return (
        <Status code={302}>
          <Redirect to={href} />
        </Status>
      )
    }
  }
}
export default Signup
